<template>
  <b-row class="cursor-pointer" @click="onBookingSelected">
    <b-col class="data-col">
      <div id="mobile-booking-list-item">
        <template v-if="showStatusBadge || showSecondaryBadges">
          <div class="mb-2 d-flex align-items-center">
            <b-badge
              v-if="showStatusBadge"
              :variant="getBookingStatusColor(status)"
              class="mb-2 mr-2 p-2 badge-text"
            >
              {{ getBookingStatusName(status, $i18n.locale) }}
            </b-badge>
            <template v-if="showSecondaryBadges">
              <b-badge
                v-if="badgeText"
                variant="light-primary"
                class="mb-2 mr-2 p-2 badge-text"
              >
                {{ badgeText }}
              </b-badge>

              <b-badge
                v-if="isNextYearBooking"
                variant="light-warning"
                class="mb-2 p-2 badge-text"
              >
                {{ $t("Booking") }} {{ bookingYear }}
              </b-badge>
            </template>
          </div>
        </template>

        <h5 v-if="showAccommodationName">
          {{ accommodationName }}
        </h5>

        <h6 class="mb-1">
          {{ client }}
        </h6>
        <div class="small text-nowrap">
          {{ localizator }}
        </div>

        <div class="mt-3">
          <div v-if="showCheckin && checkinText">
            <feather-icon icon="LogInIcon" class="mr-2" /><span class="small">{{
              checkinText
            }}</span>
          </div>
          <div v-if="showCheckout && checkoutText">
            <feather-icon icon="LogOutIcon" class="mr-2" /><span
              class="small"
              >{{ checkoutText }}</span
            >
          </div>
          <div v-if="showNights">
            <feather-icon icon="MoonIcon" class="mr-2" /><span class="small">{{
              nights
            }}</span>
          </div>
          <div v-if="showGuests">
            <feather-icon icon="UsersIcon" class="mr-2" /><span class="small">{{
              guests
            }}</span>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex justify-content-center align-items-center arrow-col"
    >
      <feather-icon
        class="icon cursor-pointer"
        size="24"
        icon="ChevronRightIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BBadge, BRow, BCol } from "bootstrap-vue";
import {
  getBookingStatusName,
  getBookingStatusColor,
  getGuestsText,
  getNightsText,
} from "@methods";
import { formatDateObjectToDate } from "@formatters";

export default {
  components: {
    BBadge,
    BRow,
    BCol,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
    hideStatusBadge: {
      type: Boolean,
      default: false,
    },
    hideSecondaryBadges: {
      type: Boolean,
      default: false,
    },
    departureBadge: {
      type: Boolean,
      default: false,
    },
    hideCheckin: {
      type: Boolean,
      default: false,
    },
    hideCheckout: {
      type: Boolean,
      default: false,
    },
    hideGuests: {
      type: Boolean,
      default: false,
    },
    hideNights: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getBookingStatusName,
      getBookingStatusColor,
    };
  },
  computed: {
    status() {
      return this.booking?.status || null;
    },
    showStatusBadge() {
      return !this.hideStatusBadge;
    },
    showSecondaryBadges() {
      return !this.hideSecondaryBadges;
    },
    showCheckin() {
      return !this.hideCheckin;
    },
    showCheckout() {
      return !this.hideCheckout;
    },
    showGuests() {
      return !this.hideGuests;
    },
    showNights() {
      return !this.hideNights;
    },
    showAccommodationName() {
      return !this.hideAccommodationName;
    },
    accommodationName() {
      return this.booking?.accommodation || null;
    },
    client() {
      return this.booking?.client || null;
    },
    checkin() {
      return this.booking?.checkin
        ? formatDateObjectToDate(this.booking.checkin)
        : null;
    },
    checkinTime() {
      return this.booking?.checkinTime || null;
    },
    checkinText() {
      const checkinText = [this.checkin];
      if (this.checkinTime) checkinText.push(this.checkinTime);
      return checkinText.length ? `${checkinText.join(" ")}` : null;
    },
    checkout() {
      return this.booking?.checkout
        ? formatDateObjectToDate(this.booking.checkout)
        : null;
    },
    checkoutTime() {
      return this.booking?.checkoutTime || null;
    },
    checkoutText() {
      const checkoutText = [this.checkout];
      if (this.checkoutTime) checkoutText.push(this.checkoutTime);
      return checkoutText.length ? `${checkoutText.join(" ")}` : null;
    },
    checkinCheckout() {
      if (!this.checkinText || !this.checkoutText) return null;
      return `${this.checkinText} - ${this.checkoutText}`;
    },
    guests() {
      return getGuestsText(
        {
          adults: this.booking?.adults || null,
          children: this.booking?.children || null,
          babies: this.booking?.babies || null,
        },
        this.$i18n.locale
      );
    },
    nights() {
      return getNightsText(this.booking?.nights, this.$i18n.locale);
    },
    localizator() {
      return this.booking?.localizator || null;
    },
    today() {
      return this.$moment().startOf("day");
    },
    bookingCheckinDate() {
      return this.$moment(this.booking.checkin).startOf("day");
    },
    bookingCheckoutDate() {
      return this.$moment(this.booking.checkout).startOf("day");
    },
    daysUntilCheckin() {
      return this.bookingCheckinDate.diff(this.today, "day");
    },
    daysUntilCheckout() {
      return this.bookingCheckoutDate.diff(this.today, "day");
    },
    isCheckin() {
      return this.today.isSame(this.bookingCheckinDate, "day");
    },
    isCurrentBooking() {
      return this.today.isBetween(
        this.bookingCheckinDate,
        this.bookingCheckoutDate,
        "day",
        "()"
      );
    },
    isCheckout() {
      return this.today.isSame(this.bookingCheckoutDate, "day");
    },
    badgeText() {
      if (!["CONFIRMED", "COMPLETED"].includes(this.status)) return null;

      if (
        !this.departureBadge &&
        this.daysUntilCheckin > 0 &&
        this.daysUntilCheckin <= 7
      ) {
        return this.$tc(
          "pages.accommodation.arrivesInXDays",
          this.daysUntilCheckin,
          { days: this.daysUntilCheckin }
        );
      }
      if (!this.departureBadge && this.isCheckin) {
        return this.$t("pages.accommodation.arrivesToday");
      }
      if (this.daysUntilCheckout > 0 && this.daysUntilCheckout <= 7) {
        return this.$tc(
          "pages.accommodation.departsInXDays",
          this.daysUntilCheckout,
          { days: this.daysUntilCheckout }
        );
      }
      if (this.isCheckout) {
        return this.$t("pages.accommodation.departsToday");
      }
      if (this.isCurrentBooking) {
        return this.$t("pages.accommodation.currentBooking");
      }
      return null;
    },
    bookingYear() {
      return this.bookingCheckinDate.year();
    },
    isNextYearBooking() {
      const currentYear = this.$moment().year();
      return this.bookingYear > currentYear;
    },
  },
  methods: {
    onBookingSelected() {
      this.$emit("booking-selected", this.booking.localizator);
    },
  },
};
</script>
<style lang="scss" scoped>
.data-col {
  overflow: hidden;
}
.arrow-col {
  min-width: 3rem;
}
#mobile-booking-list-item {
  position: relative;
  .badge-text {
    font-size: 0.7rem;
  }
}
</style>
