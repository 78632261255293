<template>
  <b-overlay :show="loading" :opacity="1">
    <b-card>
      <v-select
        v-if="filters.length"
        v-model="filter"
        :options="filters"
        :reduce="(option) => option.value"
        class="w-100 mb-4"
        :clearable="false"
        :searchable="false"
      />
      <div v-for="(booking, index) in filteredBookings" :key="booking.uuid">
        <mobile-bookings-list-item
          :booking="booking"
          :hide-accommodation-name="hideAccommodationName"
          :hide-status-badge="hideStatusBadge"
          @booking-selected="onBookingSelected"
        />
        <hr v-if="index < filteredBookings.length - 1" class="my-4" />
      </div>

      <div
        v-if="!filteredBookings.length"
        class="d-flex justify-content-center align-items-center flex-column py-5"
      >
        <feather-icon size="20" icon="ListIcon" class="mb-3" />
        <div class="text-center">
          {{ noFilteredBookingsText }}
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import MobileBookingsListItem from "@/views/bookings/list/components/MobileBookingsListItem.vue";
import { BCard, BOverlay } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    MobileBookingsListItem,
    vSelect,
    BOverlay,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    bookings: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: null,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: null,
    };
  },
  computed: {
    today() {
      return this.$moment().startOf("day");
    },
    filteredBookings() {
      return this.bookings
        .filter((booking) => {
          const bookingCheckinDate = this.$moment(
            booking.checkin.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");
          const bookingCheckoutDate = this.$moment(
            booking.checkout.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");

          switch (this.filter) {
            case "CONFIRMED":
            case "UPCOMING":
              return (
                !booking.cancelled &&
                bookingCheckinDate.isSameOrAfter(this.today, "day")
              );
            case "CURRENT":
              return (
                !booking.cancelled &&
                this.today.isBetween(
                  bookingCheckinDate,
                  bookingCheckoutDate,
                  "day",
                  "[]"
                )
              );
            case "COMPLETED":
              return booking.completed;
            case "CANCELLED":
              return booking.cancelled;
            default:
              return true;
          }
        })
        .sort((a, b) => {
          switch (this.filter) {
            case "CONFIRMED":
            case "UPCOMING":
            case "CURRENT":
              return new Date(a.checkin) - new Date(b.checkin);
            case "COMPLETED":
              return new Date(b.checkout) - new Date(a.checkout);
            case "CANCELLED":
            default:
              return new Date(b.date) - new Date(a.date);
          }
        })
        .map((booking) => ({
          uuid: booking.uuid || this.$t("Not defined"),
          date: new Date(booking.date) || this.$t("Not defined"),
          localizator: booking.localizator || this.$t("Not defined"),
          status: booking.status || this.$t("Not defined"),
          source: booking.source || this.$t("Not defined"),
          client: booking.client?.fullName || this.$t("Not defined"),
          accommodation: booking.accommodation?.name || this.$t("Not defined"),
          checkin: new Date(booking.checkin) || this.$t("Not defined"),
          checkinTime: booking.details?.accommodationArrivalTime || null,
          checkout: new Date(booking.checkout) || this.$t("Not defined"),
          checkoutTime: booking.details?.accommodationDepartureTime || null,
          adults: booking.adults || null,
          children: booking.children || null,
          babies: booking.babies || null,
          nights: booking.nights || null,
        }));
    },
    noFilteredBookingsText() {
      switch (this.filter) {
        case "CONFIRMED":
        case "UPCOMING":
          return this.$t("pages.accommodation.noUpcomingBookings");
        case "CURRENT":
          return this.$t("pages.accommodation.noCurrentGuests");
        case "COMPLETED":
          return this.$t("pages.accommodation.noCompletedBookings");
        case "CANCELLED":
          return this.$t("pages.accommodation.noCancelledBookings");
        default:
          return this.$t("pages.accommodation.noBookings");
      }
    },
    storedBookingsListFilter() {
      return this.$store.getters["ui/bookingsListFilter"];
    },
    hideStatusBadge() {
      return this.filter !== "ALL";
    },
  },
  watch: {
    filter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("ui/setBookingsListFilter", newValue);
      }
    },
  },
  created() {
    this.initFilter();
  },
  methods: {
    initFilter() {
      if (!this.filters.length) return;

      if (this.storedBookingsListFilter) {
        this.filter = this.storedBookingsListFilter;
      } else {
        this.filter = this.filters[0].value;
      }
    },
    onBookingSelected(localizator) {
      this.$emit("booking-selected", localizator);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
