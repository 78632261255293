<template>
  <div class="bookings-table">
    <!-- FILTERS -->
    <div class="d-flex justify-content-between mb-4">
      <div>
        <b-button
          v-for="option in filters"
          :key="option.value"
          :variant="bookingsFilter === option.value ? 'primary' : 'link'"
          pill
          @click="bookingsFilter = option.value"
        >
          {{ option.label }}
        </b-button>
      </div>
      <div>
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="textFilter"
          type="search"
          :placeholder="`${$t('Search')}...`"
          :disabled="loading"
          autofocus
        />
      </div>
    </div>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="filteredBookings"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="textFilter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Loading") }}...</strong>
            </div>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <div class="text-nowrap">
              {{ data.value }}
            </div>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge :variant="bookingStatusColor(data.item.status)">
              {{ bookingStatusName(data.item.status) }}
            </b-badge>
          </template>

          <!-- Column: checkin -->
          <template #cell(checkin)="data">
            <span>
              {{ formatDateStringToDate(data.item.checkin) }}
            </span>
            <b-badge v-if="badgeText(data.item)" variant="light-primary">
              {{ badgeText(data.item) }}
            </b-badge>

            <b-badge
              v-if="isFutureYearBooking(data.item)"
              variant="light-warning"
            >
              {{ $t("Booking") }} {{ bookingCheckinYear(data.item) }}
            </b-badge>
          </template>

          <!-- Column: checkout -->
          <template #cell(checkout)="data">
            {{ formatDateStringToDate(data.item.checkout) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="bookings.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per page')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BSpinner,
  BBadge,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { getBookingStatusName, getBookingStatusColor } from "@methods";
import { formatDateStringToDate } from "@formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BSpinner,
    BBadge,
    BFormInput,
    BButton,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    filters: {
      type: Array,
      default: null,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bookingsFilter: null,
      textFilter: null,
      sortBy: "date",
      sortDesc: true,
      perPage: 10,
      pageOptions: [5, 10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      formatDateStringToDate,
    };
  },
  computed: {
    fields() {
      const fields = [];
      fields.push({
        key: "localizator",
        label: this.$t("Booking nº"),
        thStyle: { width: "150px" },
      });
      if (!this.hideAccommodationName)
        fields.push({
          key: "accommodation",
          label: this.$t("Accommodation"),
          sortable: true,
        });
      fields.push({
        key: "checkin",
        label: this.$t("Check-in"),
        sortable: true,
      });
      fields.push({
        key: "checkout",
        label: this.$t("Check out"),
        sortable: true,
      });
      fields.push({ key: "client", label: this.$t("Client") });
      fields.push({
        key: "status",
        label: this.$t("Status"),
        sortable: true,
        thStyle: { width: "150px" },
      });

      return fields;
    },
    today() {
      return this.$moment().startOf("day");
    },
    filteredBookings() {
      return this.bookings
        .filter((booking) => {
          const bookingCheckinDate = this.$moment(
            booking.checkin.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");
          const bookingCheckoutDate = this.$moment(
            booking.checkout.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");

          switch (this.bookingsFilter) {
            case "CONFIRMED":
            case "UPCOMING":
              return (
                !booking.cancelled &&
                bookingCheckinDate.isSameOrAfter(this.today, "day")
              );
            case "CURRENT":
              return (
                !booking.cancelled &&
                this.today.isBetween(
                  bookingCheckinDate,
                  bookingCheckoutDate,
                  "day",
                  "[]"
                )
              );
            case "COMPLETED":
              return booking.completed;
            case "CANCELLED":
              return booking.cancelled;
            default:
              return true;
          }
        })
        .map((booking) => ({
          uuid: booking.uuid || this.$t("Not defined"),
          date: booking.date || this.$t("Not defined"),
          localizator: booking.localizator || this.$t("Not defined"),
          status: booking.status || this.$t("Not defined"),
          source: booking.source || this.$t("Not defined"),
          client: booking.client?.fullName || this.$t("Not defined"),
          accommodation: booking.accommodation?.name || this.$t("Not defined"),
          checkin: booking.checkin || this.$t("Not defined"),
          checkinTime: booking.details?.accommodationArrivalTime || null,
          checkout: booking.checkout || this.$t("Not defined"),
          checkoutTime: booking.details?.accommodationDepartureTime || null,
          adults: booking.adults || null,
          children: booking.children || null,
          babies: booking.babies || null,
          nights: booking.nights || null,
        }));
    },
    storedBookingsListFilter() {
      return this.$store.getters["ui/bookingsListFilter"];
    },
  },
  watch: {
    filteredBookings(filteredBookings) {
      this.totalRows = filteredBookings.length;
    },
    bookingsFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("ui/setBookingsListFilter", newValue);
      }
      switch (newValue) {
        case "CONFIRMED":
        case "UPCOMING":
          this.sortBy = "checkin";
          this.sortDesc = false;
          break;
        case "CURRENT":
          this.sortBy = "checkin";
          this.sortDesc = false;
          break;
        case "COMPLETED":
          this.sortBy = "checkout";
          this.sortDesc = true;
          break;
        case "CANCELLED":
        default:
          this.sortBy = "date";
          this.sortDesc = true;
          break;
      }
    },
  },
  mounted() {
    this.totalRows = this.bookings.length;
    this.initFilters();
  },
  methods: {
    initFilters() {
      if (!this.filters.length) return;

      if (this.storedBookingsListFilter) {
        this.bookingsFilter = this.storedBookingsListFilter;
      } else {
        this.bookingsFilter = this.filters[0].value;
      }
    },
    formatBookingDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("Not defined")
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(booking) {
      this.$emit("booking-selected", booking.localizator);
    },
    bookingStatusName(status) {
      return getBookingStatusName(status, this.$i18n.locale);
    },
    bookingStatusColor(status) {
      return getBookingStatusColor(status);
    },
    bookingCheckinDate(booking) {
      return this.$moment(booking.checkin).startOf("day");
    },
    bookingCheckinYear(booking) {
      return this.$moment(booking.checkin).year();
    },
    bookingCheckoutDate(booking) {
      return this.$moment(booking.checkout).startOf("day");
    },
    daysUntilCheckin(booking) {
      return this.bookingCheckinDate(booking).diff(this.today, "day");
    },
    daysUntilCheckout(booking) {
      return this.bookingCheckoutDate(booking).diff(this.today, "day");
    },
    isCheckin(booking) {
      return this.today.isSame(this.bookingCheckinDate(booking), "day");
    },
    isCurrentBooking(booking) {
      return this.today.isBetween(
        this.bookingCheckinDate(booking),
        this.bookingCheckoutDate(booking),
        "day",
        "()"
      );
    },
    isCheckout(booking) {
      return this.today.isSame(this.bookingCheckoutDate(booking), "day");
    },
    isFutureYearBooking(booking) {
      const currentYear = this.$moment().year();
      return this.bookingCheckinYear(booking) > currentYear;
    },
    badgeText(booking) {
      if (!["CONFIRMED", "COMPLETED"].includes(booking.status)) return null;

      if (
        this.daysUntilCheckin(booking) > 0 &&
        this.daysUntilCheckin(booking) <= 7
      ) {
        return this.$tc(
          "pages.accommodation.arrivesInXDays",
          this.daysUntilCheckin(booking),
          { days: this.daysUntilCheckin(booking) }
        );
      }
      if (this.isCheckin(booking)) {
        return this.$t("pages.accommodation.arrivesToday");
      }
      if (
        this.daysUntilCheckout(booking) > 0 &&
        this.daysUntilCheckout(booking) <= 7
      ) {
        return this.$tc(
          "pages.accommodation.departsInXDays",
          this.daysUntilCheckout(booking),
          { days: this.daysUntilCheckout(booking) }
        );
      }
      if (this.isCheckout(booking)) {
        return this.$t("pages.accommodation.departsToday");
      }
      if (this.isCurrentBooking(booking)) {
        return this.$t("pages.accommodation.currentBooking");
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.bookings-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
